import { Component, HostListener, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import { AccountService } from '../../services/account.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { environment } from '@environments/environment';
import { Quiz, QuizzesData, ShowProfileResponse } from '@interfaces/auth.interface';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { AvatarsProfileComponent } from '../../../../components/avatars-profile/avatars-profile.component';
import { MatDialog } from '@angular/material/dialog';

import {reload} from '@helpers/session.helper';

interface Card {
    id: number;
    name: string;
    description: string;
    value: string;
    image_path: string;
}

import { MatChipListboxChange } from '@angular/material/chips';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
})

export class SettingsComponent {

    public hide: boolean = true;
    public spinner: boolean = false;  
    public form1!: FormGroup;
    public url_dashboard: string = '/auth/mi-cuenta';
    public user_id!: number;
    public image_path: string = ''; 
    public customer_uuid: string = '';
    public porcentaje !: string;
    public n_resp!: number;
    private url: string = environment.baseUrl;
    
    public activeC = true;
    public quiz_active = true;
    public affinities_active = true;
    public gender: string | null = null;

    public clothes_gender: Quiz | null = null;

    public accesories: Quiz[] = [];

    public brand_quiz: Quiz | null = null;

    public cards: Quiz[] = [];

    isMobileView = false;
    public  y = 0;

    constructor (
        private _router: Router,
        private _formBuilder: FormBuilder,
        private _accountService: AccountService,
        private _authService: AuthService,
        private titleService: Title,
        private _bottomSheet: MatBottomSheet,
        private dialog: MatDialog,

    ) { 
        this.y = 0;
        this.n_resp = 0;
        this.createForm();
        this.titleService.setTitle('Vecsa Hidalgo | Perfil');
        this.createForm();
        this.getCustomerQuizzes();
        this.getUser();
        this.url_dashboard = this.get_url_dashboard();
        this.checkViewport();
        
    }

    public get_url_dashboard() {
        
        let role: any = localStorage.getItem('role');
        
        if(role != null){
    
            if(role === 'client')
                return `/auth/mi-cuenta`
    
            return `/admin/${role}`;
        }
    
        return `/admin/not-autorized`;
    
      }

    public get nicknameInvalid() {
        return this.form1.get('nickname')?.invalid && (this.form1.get('nickname')?.dirty || this.form1.get('nickname')?.touched);
    }

    public get nameInvalid() {
        return this.form1.get('name')?.invalid && (this.form1.get('name')?.dirty || this.form1.get('name')?.touched);
    }

    public get lastnameInvalid() {
        return this.form1.get('last_name')?.invalid && (this.form1.get('last_name')?.dirty || this.form1.get('last_name')?.touched);
    }

    public get phoneOneInvalid() {
        return this.form1.get('phone_1')?.invalid && (this.form1.get('phone_1')?.dirty || this.form1.get('phone_1')?.touched);
    }

    public get phoneTwoInvalid() {
        return this.form1.get('phone_2')?.invalid && (this.form1.get('phone_2')?.dirty || this.form1.get('phone_2')?.touched);
    }

    public get genderInvalid() {
        return this.form1.get('gender')?.invalid && (this.form1.get('gender')?.dirty || this.form1.get('gender')?.touched);
    }

    public get emailOneInvalid() {
        return this.form1.get('email_1')?.invalid && (this.form1.get('email_1')?.dirty || this.form1.get('email_1')?.touched);
    }

    public get emailTwoInvalid() {
        return this.form1.get('email_2')?.invalid && (this.form1.get('email_2')?.dirty || this.form1.get('email_2')?.touched);
    }
  
    /**
     * Form Initialization
     */
    private createForm() {
        this.form1 = this._formBuilder.group({
            nickname: ['', [Validators.required, Validators.maxLength(25), Validators.pattern(/^[a-zA-ZÀ-ÿ0-9_ .]+$/)]], //se agrego el punto en los caracteres aceptados
            name: ['', [Validators.required, Validators.pattern("[a-zA-ZÀ-ÿ ]+")]],
            last_name: ['', [Validators.required, Validators.pattern("[a-zA-ZÀ-ÿ ]+")]],
            phone_1: ['', [this.phoneValidator.bind(this)]],
            phone_2: ['', [this.phoneValidator.bind(this)]],
            gender: ['',],
            email_1: [{ value: '', disabled: true }, [Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
            email_2: ['', [Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
            uuid: ['', [Validators.required]], 
        });
    }
  
    private phoneValidator(control: AbstractControl) {
        const phone = control.value;

        if (!phone) {
            return null; // If the field is empty, it's valid
        }

        // If a phone number is provided, validate the format
        const phonePattern = /^[0-9]+$/;
        const valid = phonePattern.test(phone) && phone.length === 10;

        if (!valid) {
        return { invalidPhone: true };
        }

        return null; // Valid phone number
    }

    private getUser() {

        this.activeC = true;

        const user = JSON.parse(localStorage.getItem('user')!);

        this._accountService.getProfile(user.uuid)
        .subscribe({
            next: ({ data }: ShowProfileResponse) => {
                
                localStorage.setItem('profile', JSON.stringify( data.profile ));
                this.form1.patchValue({
                    nickname: data.user.nickname,
                    name: data.profile.name,
                    last_name: data.profile.last_name,
                    phone_1: data.profile.phone_1 || '',
                    phone_2: data.profile.phone_2 || '',
                    gender: data.profile.gender,
                    email_1: data.profile.email_1,
                    email_2: data.profile.email_2,
                    uuid: data.user.uuid,
                });


                if(data.user.nickname != null  && data.user.nickname != ''){
                    this.n_resp++;
                }
                if(data.profile.name != null  && data.profile.name != ''){
                    this.n_resp++;
                }
                if(data.profile.last_name != null  && data.profile.last_name != ''){
                    this.n_resp++;
                }
                if(data.profile.phone_1 != null  && data.profile.phone_1 != ''){
                    this.n_resp++;
                }
                if(data.profile.phone_2 != null && data.profile.phone_2 != ''){
                    this.n_resp++;
                }
                if(data.profile.email_2 != null  && data.profile.email_2 != ''){
                    this.n_resp++;
                }
                if(data.profile.picture != null  && data.profile.picture != ''){
                    this.n_resp++;
                }
                //this.getCustomerQuizzes();
                this.calcPorc(this.n_resp);

                if (data.profile.phone_1) {
                    this.form1.get('phone_1')?.disable();
                }

                this.customer_uuid = data.profile.uuid;
                this.image_path = data.profile.picture || `assets/icons/profile.svg`;
                this.activeC = false;
            },
            error: (error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oupps..',
                    text: 'Ocurrió un error al obtener su información, vuelva a intentarlo más tarde. ' + error.error.message,
                    showConfirmButton: true,
                    confirmButtonColor: '#EEB838',
                    timer: 3500
                });
            }
        });
    }

    public calcPorc( n: number){
        console.log(n);
    this.porcentaje = (((n)/16)*100).toFixed(2);
    }

    public onSubmit() { 
        this.spinner = true;
        this.form1.patchValue({
            gender: this.gender,
        });
        
        this._accountService.updateProfile(this.form1.value)
        .subscribe({
            next: () => {
                this.spinner = false;
                Swal.fire({
                    icon: 'success',
                    title: 'Actualización',
                    text: 'Actualización exitosa.',
                    showConfirmButton: true,
                    confirmButtonColor: '#EEB838',
                    timer: 3500
                });
                this.n_resp= this.n_resp - 6;
                this.getUser();
            },
            error: (error) => {
                this.spinner = false;
                reload(error, this._router);
            }
        });
    }

    public convertMayus(event: Event): string {
        const target = event.target as HTMLInputElement;
        return target.value = target.value.toUpperCase();
    }

    public updateImage(fileEvent: Event) {
        
        const target = fileEvent.target as HTMLInputElement;
        const file = target.files?.[0];
        
        if (!file) return;

        const user = JSON.parse(localStorage.getItem('user')!);

        this._accountService.updateImageProfile(user.uuid, file)
        .subscribe({
            
            next: () => {
                Swal.fire({
                    icon: 'success',
                    title: 'Actualización',
                    text: 'Actualización exitosa.',
                    showConfirmButton: true,
                    confirmButtonColor: '#EEB838',
                    timer: 3500
                });
                this.n_resp = this.n_resp - 7;
                this.getUser();
            
            },
            error: (error:any) => {
                reload(error, this._router);
            }
        });
    }

    public avatar () {
        const dialogRef = this.dialog.open(AvatarsProfileComponent, { 
            width: '900px', 
            height: '600px',
            data: {
                page: 'edit'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.image_path = result;
            }
        });
    }
    
    public getCustomerQuizzes(){

        this.quiz_active = true;

        this.affinities_active = true;

        const profile = JSON.parse(localStorage.getItem('profile')!);
    
        this._accountService.customerQuizzes(profile.uuid)
        .subscribe({
            
            next: ( quizzes: QuizzesData) => {

                this.clothes_gender = quizzes.data[0];

                this.gender =  (quizzes.data[0].selected_value == "undefined")? 'null':  quizzes.data[0].selected_value;

                this.accesories = quizzes.data.filter(quiz => quiz.group_name === 'profile_affinities');;
                this.accesories.forEach(element => {
                   if(element.question_type === 'ropa'){
                    if(element.selected_value != 'undefined' && element.selected_value != null){
                        this.n_resp++;
                    }
                   }else{
                            
                            
                            // let variantMasculina = element?.question_type === 'ropa masculina';
                            // let variantFemenina = element?.question_type === 'ropa femenina';
                            // let selectedU = element.selected_value!=null && element.selected_value!= 'undefined'
                            // if(variantFemenina || variantMasculina){
                            //     selectedU ? this.n_resp++ : this.n_resp--;
                            //     console.log(selectedU);
                            // }
                            // if(this.gender == 'H'){
                            //     if(element?.question_type === 'ropa masculina'){
                            //         if(element.selected_value != 'undefined' && element.selected_value != null)  {
                            //             this.n_resp++;
                            //            }
                            //     }
                            // }
                            // if(this.gender == 'M'){
                            //     if(element?.question_type === 'ropa femenina'){
                            //         if(element.selected_value != 'undefined' && element.selected_value != null)  {
                            //             this.n_resp++;
                            //            }
                            //     }
                            // }
                   }
                });

                const validateVariants = (name: string): boolean => {

                    let variantMasculina = this.accesories.find(quiz => quiz.name === name && quiz.question_type === 'ropa masculina');
                    let variantFemenina = this.accesories.find(quiz => quiz.name === name && quiz.question_type === 'ropa femenina');
                    // console.log('variante hombre',variantMasculina?.selected_value != null && variantMasculina?.selected_value != 'undefined');
                    // console.log('variante mujer',variantFemenina?.selected_value != null && variantFemenina?.selected_value != 'undefined');
                    const isMasculinaValid = variantMasculina ? variantMasculina.selected_value != null && variantMasculina.selected_value != 'undefined' : false;
                    const isFemeninaValid = variantFemenina ? variantFemenina.selected_value != null && variantFemenina.selected_value != 'undefined' : false;
                
                    return isMasculinaValid || isFemeninaValid;
                };
        
                let Valid = validateVariants('Calzado');
                Valid ? this.n_resp++ : '';
                let pantalonValid = validateVariants('Pantalón');
                pantalonValid ?  this.n_resp++ : '';
                
            
                this.brand_quiz = quizzes.data[11];
                if(this.brand_quiz.selected_value != 'undefined'){
                    this.n_resp++;
                }
                //this.calcPorc(this.n_resp);
                this.cards = quizzes.data.filter(quiz => quiz.group_name === 'event_preferences');

                this.cards.sort((a, b) => {
                    const numA = isNaN(Number(a.selected_value)) ? 0 : Number(a.selected_value);
                    const numB = isNaN(Number(b.selected_value)) ? 0 : Number(b.selected_value);
                    return numA - numB;
                });

                this.quiz_active = false;

                this.affinities_active = false;

            },
            error: (err) => {
                reload(err, this._router);
            }
        });
    }

    onChipGenderChange(event: MatChipListboxChange, quiz_uuid: string) {

        this.gender = event.value;
        const type = 'genero';
        this.attachQuiz(this.customer_uuid , quiz_uuid, event.value, type);

    }

    onChipBrandChange(event: MatChipListboxChange, quiz_uuid: string) {
        const type = 'marca';
        this.attachQuiz(this.customer_uuid , quiz_uuid, event.value, type);

    }

    onChipSelectionChange(event: MatChipListboxChange, quiz_uuid: string) {
        const type = 'ropa';
        this.attachQuiz(this.customer_uuid , quiz_uuid, event.value, type);

    }

    drop(event: CdkDragDrop<Quiz[]>) {
        if (event.previousIndex !== event.currentIndex) {

            moveItemInArray(this.cards, event.previousIndex, event.currentIndex);

            const quiz_uuids = this.cards.map(card => card.uuid);

            const selected_values = this.cards.map((card, index) => index.toString());

            this.attachQuizzes(this.customer_uuid , quiz_uuids, selected_values);

        }
    }

    checkViewport() {
        this.isMobileView = window.innerWidth <= 768;
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.checkViewport();
    }

    public attachQuiz (customer_uuid: string, quiz_uuid:string, selected_value: string, type: string){
        this._accountService.attatchQuiz(customer_uuid,quiz_uuid, selected_value)
        .subscribe({
            next: () => {

                if(type=='marca'){
                    if(selected_value!= null && selected_value != 'undefined'){
                        this.n_resp++;
                    }else{
                        this.n_resp--;
                    }
                    console.log(this.n_resp);
                }else{
                    if(type=='ropa'){
                        let datos = this.accesories.find(quiz => quiz.uuid === quiz_uuid);
                        //console.log(datos);
                        if(datos?.question_type === 'ropa'){
                            selected_value!=null && selected_value != 'undefined' ? this.n_resp++ : this.n_resp--;
                            this.calcPorc(this.n_resp);
                        }else{
                            let prenda = datos?.name ? datos.name : '';
                            // this.n_resp = 0;
                            // this.getCustomerQuizzes();
                            // this.getUser();

                            const profile = JSON.parse(localStorage.getItem('profile')!);
    
                                this._accountService.customerQuizzes(profile.uuid)
                                .subscribe({
                                    
                                    next: ( quizzes: QuizzesData) => {
                                        this.accesories = quizzes.data.filter(quiz => quiz.group_name === 'profile_affinities');;
                                        const validateVariants = (name: string): boolean => {

                                            let variantMasculina = this.accesories.find(quiz => quiz.name === name && quiz.question_type === 'ropa masculina');
                                            let variantFemenina = this.accesories.find(quiz => quiz.name === name && quiz.question_type === 'ropa femenina');
                                           
                                            const isMasculinaValid = variantMasculina ? variantMasculina.selected_value != null && variantMasculina.selected_value != 'undefined' : false;
                                            const isFemeninaValid = variantFemenina ? variantFemenina.selected_value != null && variantFemenina.selected_value != 'undefined' : false;
                                        
                                            return isMasculinaValid || isFemeninaValid;
                                        };
                                
                                        let Valid = validateVariants(prenda);
                                        console.log(prenda);
                                        console.log(Valid);
                                        Valid ? this.n_resp++ : '';
                                        if(Valid == false){
                                            this.n_resp--;
                                        }
                                        console.log(this.n_resp);
                                        this.calcPorc(this.n_resp);
                                    }
                                })
                        }
                    }
                    else{
                        if(type == 'genero'){
                            // this.n_resp = 0;
                            // this.getCustomerQuizzes();
                            // this.getUser();
                        }
                    }
                }
                // this.n_resp = 0;
                // this.getCustomerQuizzes();
                // this.getUser();
               
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.onmouseenter = Swal.stopTimer;
                      toast.onmouseleave = Swal.resumeTimer;
                    }
                  });
                  Toast.fire({
                    icon: "success",
                    title: "Guardado..."
                  });

            },
            error: (error) => {
                reload(error, this._router);
            }
        });
    }

    public attachQuizzes (customer_uuid: string, quiz_uuids:string[], selected_values: string[]){
        
        this._accountService.attatchQuizzes(customer_uuid,quiz_uuids,selected_values)
        .subscribe({
            next: () => {

                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.onmouseenter = Swal.stopTimer;
                      toast.onmouseleave = Swal.resumeTimer;
                    }
                  });
                  Toast.fire({
                    icon: "success",
                    title: "Guardado..."
                  });

            },
            error: (error) => {
                reload(error, this._router);
            }
        });
    }

    @HostListener('window:scroll', [])
    scrollToTop() {
      const element = document.getElementById('top'); // Asegúrate de tener un elemento con este ID
      if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
      }
  }
}
