import { Component } from '@angular/core';

@Component({
  selector: 'app-cardshop',
  templateUrl: './cardshop.component.html',
  styleUrls: ['./cardshop.component.css']
})
export class CardshopComponent {

}
