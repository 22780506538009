<mat-sidenav-container fullscreen >
    <span id="moveTop"></span>
    <div class="content" style="min-height: 85%;">
        <!-- <app-new-nav></app-new-nav> -->
        <router-outlet></router-outlet>
    </div>
     
    <div #footer>
        <app-footer></app-footer>
    </div>
    
</mat-sidenav-container>