import { Component, Input } from '@angular/core';
import { Events } from '@interfaces/community.interface';

@Component({
  selector: 'app-communitycard',
  templateUrl: './communitycard.component.html',
  styleUrls: ['./communitycard.component.css']
})
export class CommunitycardComponent {
  @Input() albums!: Events[];


}
