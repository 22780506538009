<div class="d-flex justify-content-center">
  <nav  id='top' class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
            <div class="container-fluid cont_edit">
                  <!-- <a href="https://grupovecsa.com/"> -->
                  <a class="" href="https://grupovecsa.com/">
                    <span><img class="img_nav" src="assets/img/GRUPO VECSA.png" alt="" style="height: 49px;"/></span>
                  </a>
                  <button class="navbar-toggler btn-menu" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span><img src="assets/icons-fontawesome/bars-solid.svg" alt="flecha" style="font-size: 40px;"></span>
                  </button>
            </div>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                      <ul class="navbar-nav ms-auto">
                          <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                              Vehículos
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                              <li><a class="dropdown-item" (click)="closeNavbar()" [routerLink]="['/compra-tu-auto/Nuevo/Bmw/sin-lineas/sin-modelos/sin-carrocerias/sin-versiones/sin-anios/10000/60000000/sin-estados/sin-busqueda/sin-transmisiones/sin-colores/sin-colores/ninguno/1']" style="font-size: 13px;">BMW</a></li>
                              <li><a class="dropdown-item" (click)="closeNavbar()" [routerLink]="['/compra-tu-auto/Nuevo/Mini/sin-lineas/sin-modelos/sin-carrocerias/sin-versiones/sin-anios/1000/60000000/sin-estados/sin-busqueda/sin-transmisiones/sin-colores/sin-colores/ninguno//1']" style="font-size: 13px;">MINI</a></li>
                              <li><a class="dropdown-item" (click)="closeNavbar()" [routerLink]="['/compra-tu-auto/Nuevo/Motorrad/sin-lineas/sin-modelos/sin-carrocerias/sin-versiones/sin-anios/10000/60000000/sin-estados/sin-busqueda/sin-transmisiones/sin-colores/sin-colores/ninguno/1']" style="font-size: 13px;">MOTORRAD</a></li>
                              <li><a class="dropdown-item" (click)="closeNavbar()" [routerLink]="['/compra-tu-auto/Seminuevo/sin-marcas/sin-lineas/sin-modelos/sin-carrocerias/sin-versiones/sin-anios/10000/60000000/sin-estados/sin-busqueda/sin-transmisiones/sin-colores/sin-colores/ninguno/1']" style="font-size: 13px;">Seminuevos</a></li>
                            </ul>
                          </li>
                        <li class="nav-item">
                          <a class="nav-link" aria-current="page" href="https://vecsaboutique.com/">Boutique</a>
                        </li>

                        <li class="nav-item"> 
                          <a class="nav-link" [routerLink]="['/auth/login']"(click)="closeNavbar()">
                            Rewards
                          </a>
                        </li>

                        <li class="nav-item">
                          <a class="nav-link" href="https://vecsaexperience.com/"  (click)="closeNavbar()">Experience</a>
                        </li>

                        <li class="nav-item">
                          <a class="nav-link" [routerLink]="['/carcare']" (click)="closeNavbar()">Car Care</a>
                        </li>
                        
                        <li class="nav-item">
                          <a class="nav-link" [routerLink]="['/promotions']" (click)="closeNavbar()">Promociones</a>
                        </li>
        
        
                        <li *ngIf="!auth_user" class="nav-item">
                          <a class="nav-link" [routerLink]="['/auth/iniciar-sesion']" (click)="closeNavbar()">
                            <img src="assets/icons-fontawesome/user-regular.svg" alt="flecha" width="22px" height="22px">
                            <p class="d-block d-lg-none"> Mi Cuenta</p>
                          </a>
                        </li>
        
                        <li *ngIf="auth_user" class="nav-item dropdown">
                          
                          <a class="nav-link dropdown-toggle" href="#" role="button" id="navbarDropdownAccount" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="assets/icons-fontawesome/user-regular.svg" alt="flecha" width="22px" height="22px">
                            <p class="d-block d-lg-none"> Mi Cuenta</p>
                          </a>
        
                          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownAccount">
                            <li><a class="dropdown-item" (click)="closeNavbar()" [routerLink]="[url_dashboard]">DASHBOARD</a></li>
                            <li><a class="dropdown-item" (click)="logout()" > CERRAR SESIÓN <i class="fas fa-sign-out-alt"></i></a></li>
                          </ul>
                        </li>
        
                      </ul>
                </div>
      </div>
    </nav>
</div>