<div id="main ">
  <app-banner></app-banner>
</div>
<app-cardshop></app-cardshop>
<app-lifestyle></app-lifestyle>

<app-promotions></app-promotions>

<app-product></app-product>

<!-- <app-form-leads></app-form-leads> -->


<app-map></app-map>