<div class="container-fluid ">
<div class="row">
    <picture>
        <!-- Para pantallas con un ancho máximo de 768px -->
        <source srcset="./assets/landings/rider/cel.jpg" media="(max-width: 768px)">
        
        <!-- Para pantallas con un ancho mayor a 768px -->
        <img src="./assets/landings/rider/banner.jpg" alt="" width="100%">
      </picture></div>
</div>
<div class="container">
    <app-survey-rider></app-survey-rider>
</div>
