import { Component } from '@angular/core';

@Component({
  selector: 'app-sk-cube',
  templateUrl: './sk-cube.component.html',
  styleUrls: ['./sk-cube.component.css'],
  standalone: true
})
export class SkCubeComponent {

}
