<app-new-nav></app-new-nav>
<div id="top"></div>
<div class="container">
    <div class="col-3">
        <a class="text-decoration-none text-dark" href="https://grupovecsa.com/" style="display: flex;">
            <img src="assets/icons-fontawesome/circle-left-regular.svg" alt="flecha" width="20px" height="20px" style="padding-right: 3px;"><span>Regresar</span>
        </a>
    </div>
    <div class="row align-items-center mt-3">
        <div class="col-3"></div>

        <div class="col-auto" >
            <h3 class="fw-bold mb-0" style="font-size: x-large;">VECSA Rewards</h3>
            <br>
            <h6 class="fw-light parrafo" >Aquí podrás consultar tus puntos acumulados, promociones para ganar más, y ventas especiales para duplicar su valor al canjearlos.</h6>
        </div>

        <div class="col-auto"></div>
    </div>

    <hr />

    <div>
        <div class="row mt-5 mb-5">
            <ng-container *ngIf="spinner; else elseTemplate" >
                <app-sk-cube></app-sk-cube>
            </ng-container>

            <ng-template #elseTemplate>

                <div class="row contenedor" id="Principal">
                    <div class="col-lg-9 col-xs-12 mt-3">
                        <div class="row">
                            <div class="col-2 col-lg-1" style="height: fit-content;">
                                <app-sidebar [promociones]="promosN"></app-sidebar>
                            </div>

                            <div class="col-10 col-lg-11 ">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="mb-4">
                                            <div class="div1" style="padding: 0 !important;">
                                                <div class="row">
                                                    <div class="col-9 texto_ini">
                                                        <h1>!Hola {{name | titlecase}}!</h1>
                                                        <p class="bienvenida">Bienvenido a tu espacio nuevamente.</p>
                                                    </div>
                                                    <div class="col-3" style="padding: 0 !important; position: relative;">
                                                        <img src="assets/img/copa.png" alt="" class="taza">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="mb-4">
                                            <div class="grafica">
                                                <h1 style="padding: 4%; padding-bottom: 0;">Puntos del mes</h1>
                                                <div class="graphic" id="linea" ></div>
                                            </div>
                                        </div>    
                                    </div>
                                </div>
                                
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="pMes">
                                                <p class="texto_puntos">Ptos. este mes:</p>
                                                <br>
                                                <h1 class="punto_N">{{puntos_mes}}</h1>
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <div class="pMes">
                                                <p class="texto_puntos">Ptos. acumulados:</p>
                                                <br>
                                                <h1 class="punto_N">{{puntos_acum}}</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-xs-12 mt-3">
                        <div class="row">
                            <div class="div1 mb-4 profile">
                                <div class="col-3" style="display: grid; justify-content: center;">
                                    <img id="imagePreview" src="{{img}}" alt="User Picture Imagen" width="60px" height="60px" style="border-radius: 50%;"/>
                                    <button mat-raised-button class="btn mt-2 px-1">
                                        <a [routerLink]="['/auth/mi-cuenta/ajustes']" class="mt-3">
                                            <span style="font-size: x-small; white-space: nowrap;"><img src="assets/icons-fontawesome/pen-to-square-solid.svg" alt="flecha" width="12px" height="12px">Editar</span>
                                        </a>
                                    </button>
                                </div>

                                <div class="col-6">
                                    <p class="texto">
                                        {{ nickname | titlecase }}
                                    </p>
                                    <p class="texto">
                                        {{ name | titlecase }} {{ lastname | titlecase }}
                                    </p>
                                    <p class="texto" style="font-size: xx-small;">
                                        {{ email }}
                                    </p>
                                    <p class="texto">
                                        {{ phone }}
                                    </p>
                                </div>

                                <div class="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <h5 style="margin: 0 !important;">Posición</h5>
                                    <span #badge class="badge posicion-u" 
                                         style="background-color: #5ba0ff; box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);">
                                    {{customerP}}
                                    </span>
                                </div>
                            </div>                            
                        </div>

                        <div class="p-2 mt-3">
                            <p style="font-size: large; color: rgb(95, 92, 92); text-align: center;">¡Participar en nuestras experiencias te da puntos!</p>
                        </div>

                        <!-- swiper -->
                        <div>
                            <div #myModal class="modal">
                                <div class="modal-container position-relative">
                                    <img class="modal-content" #img01>
                                    <span class="btnClose" (click)="closeModal('yes')">
                                        <i class="fa-solid fa-circle-xmark icon"></i>
                                    </span>

                                    <div class="texto"> 
                                        <div #caption></div> 
                                    </div> 
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <swiper-container class="vertical_S" direction="vertical" pagination="true" pagination-clickable="true" space-between="30" [slidesPerView]="tagsvert">
                                <swiper-slide class="slider-c2" *ngFor="let image of images">
                                    <div class="row align-items-center">
                                        <div class="col-4">
                                            <div class="" (click)="showModal(image.image_path)">
                                                <img class="img-small" src="{{ image.image_path }}" alt="{{ image.name }}">
                                            </div>
                                        </div>
                                    
                                        <div class="col-8 d-flex flex-column">
                                            <h1 class="slide-title mb-2">{{ image.name | slice:0:15 }}</h1>
                                            <h2 class="slide-title-date mb-4">{{ image.begin_date }}</h2>
                                    
                                            <div class="d-flex justify-content-between align-items-center mt-auto">
                                                <div></div>
                                                <button class="slide-btn btn btn-primary" type="submit">Reservar</button>
                                            </div>
                                        </div>
                                    </div>
                                </swiper-slide>  
                            </swiper-container>
                        </div>
                    </div>
                </div>

                <div class="records row">
                    <div class="col-12 col-lg-6" style="padding: 0;" *ngFor="let rider of points; let i = index">
                        <div class="row tarjeta">
                            <div class="col-2 d-grid align-items-center" >
                                <img *ngIf="rider.picture!=null" id="imagePreview" src="{{rider.picture}}" alt="User Picture Imagen" class="img-tags" />
                                <img *ngIf="rider.picture==null" id="imagePreview" src="assets/icons/profile.svg" alt="User Picture Imagen" class="img-tags" />
                            </div>

                            <div class="col-5 d-grid align-items-center" >
                               <h1 class="users">{{rider.nickname}}</h1>
                            </div>

                            <div class="col-3 d-grid align-items-center"> 
                                 <h1 class="posicion-p"> <p style="font-size: large; margin: 0 !important; padding: 0;">Puntos</p> {{rider.total_earned_points}}</h1>
                            </div>
                            
                            <div class="col-2 d-grid align-items-center justify-content-center">
                                <h1 class="punto_N" style="padding-top: 2rem;">{{rider.position}}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                
               <!-- <div class="row promos" id="promosN">
                    <h1>Promociones</h1>
                    <swiper-container class="s-promos" #swiperC [slidesPerView]="anchoCards" pagination="true" pagination-clickable="true" zoom="true">
                        <swiper-slide class="slider2" *ngFor="let image of promos">
                            <div class="sl2" (click)="showModal(image.url)">
                                    <img class="imgS2" src="{{image.url}}" alt="image.name">
                            </div>
                        </swiper-slide>  
                    </swiper-container>
                </div> -->
                
                <div class="img-banner">  
                    <img class="img-banner" src="assets/img/banner.jpg" alt="Eventos Proximos">
                </div>

            </ng-template>
        </div>
    </div>
</div>

<div class="return-container">
    <a (click)="scrollToTop()" class="return-link">
        <img src="assets/icons-fontawesome/circle-up-solid.svg" alt="flecha" width="35px" height="35px">
    </a>
</div>
 