<div #myModal class="modal">
    <div class="modal-container position-relative">
        <img class="modal-content" #img01>
        <span class="btnClose" (click)="closeModal('yes')">
            <i class="fas fa-times-circle icon"></i>
        </span>
        <div class="texto"> 
            <div #caption></div> 
        </div> 
    </div>
</div>