 <div class="container-fluid">
     <div class="row">
         <div class="col-lg-4 col-md-4 col-sm-6 col-6  ">
             <div class="card" style="display: block; margin: auto;  ">
                 <div class="card-body">
                     <h2 class="card-title text-center centro">BMW</h2>
                     <a class="btn" target="_blank"
                         href="https://walink.co/f25753">Contacto</a>
                 </div>
             </div>
         </div>
         <div class="col-lg-4 col-md-4 col-sm-6 col-6  ">
             <div class="card" style="display: block; margin: auto;  ">
                 <div class="card-body">
                     <h2 class="card-title text-center centro">MINI</h2>
                     <a class="btn" target="_blank"
                         href="https://walink.co/f25753">Contacto</a>
                 </div>
             </div>
         </div>
         <div class="col-lg-4 col-md-4 col-sm-6 col-6  ">
            <div class="card" style="display: block; margin: auto;  ">
                <div class="card-body">
                    <h2 class="card-title text-center centro">MOTORRAD</h2>
                    <a class="btn" target="_blank"
                        href="https://walink.co/f25753">Contacto</a>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6 col-6  ">
            <div class="card" style="display: block; margin: auto;  ">
                <div class="card-body">
                    <h2 class="card-title text-center centro">SEMINUEVOS</h2>
                    <a class="btn" target="_blank"
                        href="https://walink.co/4e8e6d">Contacto</a>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6 col-6  ">
            <div class="card" style="display: block; margin: auto;  ">
                <div class="card-body">
                    <h2 class="card-title text-center centro">SERVICIO</h2>
                    <a class="btn" target="_blank"
                        href="https://walink.co/4e8e6d">Contacto</a>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6 col-6  ">
            <div class="card" style="display: block; margin: auto;  ">
                <div class="card-body">
                    <h2 class="card-title text-center centro">REFACCIONES</h2>
                    <a class="btn" target="_blank"
                        href="https://walink.co/4e8e6d">Contacto</a>
                </div>
            </div>
        </div>
     </div>
 </div>

 