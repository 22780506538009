<div class="container">
    <div class="row">
        <div class="col-12 py-5" style="margin-top: 95px; margin-bottom: 200px;">
            <div class="row">
                <h1 class="fs-3 fw-bold mb-4 text-center mx-auto">Crea una contraseña segura</h1>
                <div class="col-12">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="row mb-2">
                            <div class="col-8 col-md-4 mx-auto">
                                <mat-form-field appearance="outline">
                                    <mat-label>Contraseña nueva</mat-label>
                                    <input matInput [type]="hide ? 'password' : 'text'" placeholder="********" formControlName="password" required>
                                    <button mat-icon-button type="button" matSuffix (click)="hide = !hide">
                                        <img [src]="hide ? 'assets/img/login/icono-ojo-abierto.png' : 'assets/img/login/icono-ojo-cerrado.png'" alt="Icono de imagen" width="24" height="24">
                                    </button>

                                </mat-form-field>

                                <mat-hint class="text-danger">
                                    <div class="row">
                                        <div class="col-12">
                                            <p *ngIf="passwordInvalid">La contraseña debe contener al menos una letra mayúscula [A-Z], una letra minúscula [a-z], un dígito [0-9], y un carácter especial permitido: &#64; $ ! % * ? &.</p>
                                        </div>
                                        <div class="col-12">
                                            <p *ngIf="passwordLength">La contraseña debe ser de minímo 8 a 32 caracteres.</p>
                                        </div>
                                    </div>
                                </mat-hint>
                            </div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-10 col-md-7 col-lg-4 mx-auto">
                                <mat-form-field appearance="outline">
                                    <mat-label>Confirmar Contraseña</mat-label>
                                    <input matInput type='password' formControlName="confirmPassword" required>
                                </mat-form-field>
                                <mat-hint class="text-danger">
                                <div class="row">
                                    <div class="col-12">
                                    <p *ngIf="form.hasError('mismatch') && (form.get('confirmPassword')!.dirty || form.get('confirmPassword')!.touched)">Las contraseñas no coinciden.</p>
                                    </div>
                                </div>
                                </mat-hint>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-8 col-md-4 mx-auto text-center mt-5">
                                <button mat-raised-button type="submit"
                                    class="btn abcars-background-color-blue mt-3  mx-2 px-3"
                                    [disabled]="form.invalid || spinner">
                                    <span *ngIf="!spinner">Restablecer contraseña</span>
                                    <span *ngIf="spinner" class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>