<div class="contenedor">
    <div class="header">
        <h1>Elegir Avatar</h1>

        <div class="buttons-header">
            <button mat-icon-button (click)="onClose()" class="close-button">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>


    <div class="picture-camera">
        <!-- Botón para abrir o volver a abrir la cámara -->
        <span>Clasificación: </span>
        <div class="icon-button" *ngIf="!capturedImage" (click)="openCamera()">
            <i class="fa-solid fa-camera-retro"></i>
            <span>Abrir Cámara</span>
        </div>
    </div>

    <div class="camera-preview" *ngIf="cameraActive">
        <video #videoElement autoplay muted></video>

        <div class="instrucciones">
            Se tomara la foto en 4 seg. Asegurate de que tu rostro este dentro del marco, antes de tomar la foto.
        </div>
    </div>

    <div class="camera">
        <!--Imagen capturada-->
        <img *ngIf="capturedImage" [src]="capturedImage" alt="Imagen capturada" />

        <!-- Botones para confirmar o cancelar -->
        <div class="buttons" *ngIf="capturedImage">
            <button mat-button class="seleccionar" (click)="confirmPhoto()">Confirmar</button>
            <button mat-button class="new" (click)="retakePhoto()">Volver a tomar foto</button>
            <button mat-button class="cancelar" (click)="cancelPhoto()">Cancelar</button>
        </div>
    </div>

    <div class="categorias">
        <!--Bontones para seleccionar categoría-->
        <button (click)="filtrarIconos ('todos')">Todos</button>
        <button (click)="filtrarIconos ('autos')">Vehículos</button>
        <button (click)="filtrarIconos ('motos')">Motos</button>
    </div>

    <hr>

    <div class="buttons">
        <button 
            mat-raised-button
            type="button"
            class="abcars-background-color-blue btnEnviar"
            (click)="enviarIcon()"
            [disabled]="!iconButton"
            [ngClass]="[!iconButton ? 'btnDisabled' : 'abcars-background-color-blue']"
        >
            Seleccionar
        </button>
    </div>

    <div class="avatars">
        <div class="row">
            <div class="col-12 columnaImagenes">
                <div class="row">
                    <div class="col-2" *ngFor="let icon of filteredIcons" (click)="setIcon(icon.src)">
                        <img [src]="icon.src" alt="Avatar" class="avatar" [class.selected]="selectedIcon === icon.src">
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>