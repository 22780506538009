<div class="container-fluid margen">
  <div class="row">
    <div class="col-md-12 col-sm-12 col-lg-6 d-flex justify-content-center align-items-center">
      <!-- Imagen para pantallas grandes (lg) -->
      <img class="img-lg" src="./assets/cards/PROMOCIONES_1600X550.webp">
      <!-- Imagen para pantallas medianas (md) -->
      <img class="img-md" src="./assets/cards/PROMOCIONES_1720X472.webp">
      <!-- Imagen para pantallas pequeñas (sm) -->
      <img class="img-sm" src="./assets/cards/PROMOCIONES_820X554.webp">
    </div>
    <div class="col-md-12 col-sm-12 col-lg-6 margen1">
      <h2 class="card-text titles" style="font-family: BMW;">PROMOCIONES VIGENTES</h2>
      <h5 class="card-title">TENEMOS EL MEJOR PLAN</h5>
      <p class="card-text txt2">
        Encuentra tu vehículo, en el plan perfecto para estrenar. Conoce las promociones que este mes tienen: BMW,
        MINI, MOTORRAD y Seminuevos.</p>
      <a [routerLink]="['/promotions']" class="btn">VER PROMOCIONES</a>
    </div>
  </div>
  <div class="row margen">
    <div class="col-md-12 col-sm-12 col-lg-6 d-flex justify-content-center align-items-center">
      <img class="img-lg" src="./assets/cards/COMUNIDAD_1600X550.webp">
      <!-- Imagen para pantallas medianas (md) -->
      <img class="img-md" src="./assets/cards/COMUNIDAD_1720X472.webp">
      <!-- Imagen para pantallas pequeñas (sm) -->
      <img class="img-sm" src="./assets/cards/COMUNIDAD_820X554.webp">
    </div>
    <div class="col-md-12 col-sm-12 col-lg-6 margen1">
      <h2 class="card-text titles" style="font-family: BMW;">COMUNIDAD VECSA HIDALGO</h2>

      <h5 class="card-title">JUNTOS LO HACEMOS MEJOR</h5>
      <p class="card-text txt2">Unete a nuestros grupos de entusiastas y sé el protagonista de nuestras próximas
        historias.</p>
        <a [routerLink]="['/community']" class="btn">VER PROXIMOS EVENTOS</a>
      </div>
  </div>
</div>
