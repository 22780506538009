<div class="container-fluid  mg ">
    <div class="row wit">
        <div class=" col-lg-6 col-md-6 col-sm-12   ">
            <h2 class="text-center titles">
                ENCUENTRA TU PRÓXIMO VEHÍCULO
            </h2>
            <img class="image" src="./assets/cards/stock_lacator.svg">
             <a class="btn"   [routerLink]="['/compra-tu-auto/sin-marcas/sin-modelos/sin-anios/5000000/Hidalgo/sin-busqueda/sin-transmisiones/Nuevo/sin-colores/sin-colores/1']"> DISPONIBILIDAD INMEDIATA</a>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 mg2  ">
            <h2 class="text-center titles">
                CITA DE SERVICIO
            </h2>
            <img class="image" src="./assets/cards/CITA_SERVICIO.svg">

             <a class="btn"  href="https://api.whatsapp.com/send?phone=527717955325&text=Quiero%20una%20cita%20de%20servicio" target="_blank">AGENDA TU CITA</a>
        </div>
    </div>
</div>