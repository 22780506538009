import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { AbstractControlOptions, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

// Animations
import Swal from 'sweetalert2';

// Services
import { AuthService } from '../../services/auth.service';

// Interfaces
import { ImageData, Quiz, QuizzesData, RegisterResponse } from '@interfaces/auth.interface';
import { MatChipListboxChange } from '@angular/material/chips';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { AvatarsProfileComponent } from '../../components/avatars-profile/avatars-profile.component';
import { AccountService } from '@services/account.service';

import {reload} from '../../../shared/helpers/session.helper';
import { firstValueFrom } from 'rxjs';
import { GralResponse } from '@interfaces/vehicle_data.interface';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css'],
})

export class RegisterComponent {

    // References of Help
    public hide: boolean = true;
    public spinner: boolean = false;
    public active: boolean = false;
    public image_path: string = `assets/img/user.jpeg`;
    public quiz_active = true;
    public affinities_active = true;
    public status: boolean = false; 

    public gender: string | null = null;
    public gender_uuid: string | null = null;
    public statusBrand: boolean = false;

    public statusCards: boolean = false;

    public size: string | null = null;
    public size_uuid: string | null = null;
    public statusClothes: boolean = false;

    public brand: string | null = null;
    public brand_uuid: string | null = null;

    public clothes_gender: Quiz | null = null;
    public accesories: Quiz[] = [];
    public brand_quiz: Quiz | null = null;

    public cards: Quiz[] = [];
    public tallas = false;

    public isMobileView: boolean = false;


    public customer_uuid: string = '';

    public isFormValid: boolean = false;

    public user_uuid: string = '';

    public file: File | null = null;

    // Form References
    public form!: UntypedFormGroup;
    public auth!: UntypedFormGroup;

    @ViewChild('myModal') modal!: ElementRef;
    @ViewChild('myImg') img!: ElementRef;
    @ViewChild('img01') modalImg!: ElementRef; 
    @ViewChild('caption') caption!: ElementRef;
    execute!:string;

    constructor(
        private _router: Router,
        private _formBuilder: UntypedFormBuilder, 
        private _authService: AuthService,
        private _accountService: AccountService,
        private titleService: Title,
        private dialog: MatDialog,
    ) { 
        // Set Title View
        this.titleService.setTitle('Vecsa Hidalgo | Registrarme');

        // Create form
        this.checkViewport();
        this.createForm();
        this.getCustomerQuizzes();
    }

    /**
     * Getters Inputs Check
     */
    get nameInvalid() {
        return this.form.get('name')!.invalid && (this.form.get('name')!.dirty || this.form.get('name')!.touched);
    }

    get lastnameInvalid() {
        return this.form.get('last_name')!.invalid && (this.form.get('last_name')!.dirty || this.form.get('last_name')!.touched);
    }

    get emailInvalid() {
        return this.form.get('email')!.invalid && (this.form.get('email')!.dirty || this.form.get('email')!.touched);
    }

    get passwordInvalid() {
        return this.form.get('password')!.invalid && (this.form.get('password')!.dirty || this.form.get('password')!.touched);
    }

    get dateInvalid() {
        return this.form.get('birthday')!.invalid && (this.form.get('birthday')!.dirty || this.form.get('birthday')!.touched);
    }
    
    get phoneInvalid(){
        const phoneControl = this.form.get('phone_1');
        const phone = phoneControl?.value;

        const isNumeric = /^\d+$/.test(phone);
        const isValidLength = phone?.length >= 10 && phone?.length <= 15;

        return phoneControl?.touched && (!isNumeric || !isValidLength);
    }

    get passwordLength() {
        let password = this.form.get('password')!.value;
        return this.form.get('password')!.touched && (password.length < 8 || password.length > 32); 
    }

    /**
     * Login Form Initialization
     */
    public createForm() {
        this.form = this._formBuilder.group({
            name: ['', [Validators.required, Validators.pattern("[a-zA-ZÀ-ÿ ]+")]],
            last_name: ['', [Validators.required, Validators.pattern("[a-zA-ZÀ-ÿ ]+")]],
            birthday: ['', Validators.required],
            email: ['', [Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
            phone_1: [
                '', 
                [Validators.required, Validators.pattern("^[0-9]+$"), Validators.minLength(10), Validators.maxLength(10)]
            ],
            password: ['', [Validators.required, Validators.pattern(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+/), Validators.minLength(8), Validators.maxLength(32)]],
            confirmPassword: ['', Validators.required]
        }, {validators: [this.passwordMatchValidator]} as AbstractControlOptions);
    }

    public async onSubmit() {
        try {
    
            Swal.fire({
                title: 'Procesando...',
                allowOutsideClick: false,
            });
                
            const response = await this.register();

            if (response && response.data && response.data.profile && response.data.profile.uuid) {
                
                this.user_uuid = response.data.user.uuid;
                this.customer_uuid = response.data.profile.uuid;

                if(this.clothes_gender != null)
                    await this.attachQuiz(this.customer_uuid , this.clothes_gender!.uuid, this.clothes_gender!.selected_value);

                if(this.brand_quiz != null)
                    await this.attachQuiz(this.customer_uuid , this.brand_quiz.uuid, this.brand_quiz.selected_value);
                

                const cards_uuids = this.cards.map(card => card.uuid);

                const cards_selected_values = this.cards.map((card, index) => index.toString());

                this.attachQuizzes(this.customer_uuid , cards_uuids, cards_selected_values);


                const accesories_uuids = this.accesories.map(accesory => accesory.uuid);

                const accesories_selected_values = this.accesories.map((accesory, index) => accesory.selected_value);

                this.attachQuizzes(this.customer_uuid , accesories_uuids, accesories_selected_values);



                if(this.file != null){
                    await this.updateImage(this.file);
                }

                localStorage.setItem('user_token', response.data.token);
                localStorage.setItem('user', JSON.stringify( response.data.user));
                localStorage.setItem('role', response.data.role);
                localStorage.setItem('profile', JSON.stringify( response.data.profile));

                Swal.fire({
                    icon: 'success',
                    title: 'Registro creado exitosamente.',
                    showConfirmButton: false,
                    timer: 2000
                });

                this._router.navigate(['/auth/mi-cuenta'])
                        
            } else {
        
                Swal.fire({
                icon: 'error',
                title: 'Error al crear el registro',
                text: 'No se recibieron los datos correctos del servidor. Intenta más tarde.',
                });
        
            }
        } catch (error: any) {
    
            Swal.fire({
                icon: 'error',
                title: 'Lo sentimos, hubo un error',
                text: 'Hubo un problema al procesar la solicitud. Inténtalo más tarde.'+error,
            });
        }
    }

    private async register(): Promise<RegisterResponse | null> {
        try {
            return await firstValueFrom(this._authService.register(
                this.form.value
            ));
        } catch (error: any) {
            console.error('Error al crear el Rider:', error);
            throw new Error('Error en la creación del Rider.');
        }
    }

    public async updateImage(file: File): Promise<ImageData> {
        
        try {
            return await firstValueFrom(
                this._accountService.updateImage(this.user_uuid, file)
            );
        } catch (error: any) {
            console.error('Error al subir la imagen:', error);
            throw new Error('Error al subir la imagen del rider.');
        }
    }

    /**
     * Checking length input   
     * @param object any input
     */
    public maxLengthCheck(object: any) {   
        if (object.value.length > object.maxLength) {
        object.value = object.value.slice(0, object.maxLength)
        }
    }

    /**
     * Helper function to convert text String to Uppercase
     * @param event keyup
     * @returns string
     */
    public convertMayus(event: any): string {
        return event.target.value = event.target.value.toUpperCase();
    }

    passwordMatchValidator(formGroup: UntypedFormGroup) {
        return formGroup.get('password')!.value === formGroup.get('confirmPassword')!.value ? null : { mismatch: true };
    }


    onChipGenderChange(event: MatChipListboxChange, quiz_uuid: string) {

        this.gender = event.value;
        
        this.clothes_gender!.selected_value = event.value

        this.statusClothes = !!this.clothes_gender!.selected_value
        
        this.status = (this.statusClothes && this.statusBrand) ? true : false;

    }

    onChipSelectionChange(event: MatChipListboxChange, quiz_uuid: string) {

        let index = this.accesories.findIndex(quiz => quiz.uuid === quiz_uuid);

        if (index !== -1) {
            this.accesories[index].selected_value = event.value;
        }

        let otherClothesValid = this.accesories
        .filter(quiz => quiz.question_type === 'ropa')
        .every(quiz => quiz.selected_value !== null && quiz.selected_value !== undefined);

        const validateVariants = (name: string): boolean => {

            let variantMasculina = this.accesories.find(quiz => quiz.name === name && quiz.question_type === 'ropa masculina');
            let variantFemenina = this.accesories.find(quiz => quiz.name === name && quiz.question_type === 'ropa femenina');

            const isMasculinaValid = variantMasculina ? variantMasculina.selected_value !== null && variantMasculina.selected_value !== undefined : false;
            const isFemeninaValid = variantFemenina ? variantFemenina.selected_value !== null && variantFemenina.selected_value !== undefined : false;
        
            return isMasculinaValid || isFemeninaValid;
        };

        let calzadoValid = validateVariants('Calzado');

        let pantalonValid = validateVariants('Pantalón');

        this.isFormValid = otherClothesValid && calzadoValid && pantalonValid;
        
    }
    

    onChipBrandChange(event: MatChipListboxChange, quiz_uuid: string) {

        this.brand_quiz!.selected_value = event.value

        this.statusBrand = !!this.brand_quiz!.selected_value
        
        this.status = (this.statusClothes && this.statusBrand) ? true : false;

        // this.attachQuiz(this.customer_uuid , quiz_uuid, event.value);
    }

    drop(event: CdkDragDrop<Quiz[]>) {
        if (event.previousIndex !== event.currentIndex) {

            moveItemInArray(this.cards, event.previousIndex, event.currentIndex);

            this.statusCards = true;

        }
    }

    checkViewport() {
        this.isMobileView = window.innerWidth <= 768;
    }

    public avatar () {
        const dialogRef = this.dialog.open(AvatarsProfileComponent, { 
            width: '900px', 
            height: '600px',
            data: {
                page: 'register'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.urlToFile(result, 'icon.png').then((file) => {
                    this.file = file;
                }).catch((error) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error al subir la imagen de su perfil.',
                        text: `Error: ${ error }`,
                    });
                });
            
                this.image_path = result;
            }
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.checkViewport();
    }

    @HostListener('window:scroll', [])
    scrollToTop() {
        const element = document.getElementById('top'); // Asegúrate de tener un elemento con este ID
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    // Función para convertir una URL a un archivo de tipo File
    public urlToFile(url: string, fileName: string): Promise<File> {
        return fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
            return new File([blob], fileName, { type: blob.type });
        });
    }

    public getCustomerQuizzes(){

        this.tallas = true;

        this.quiz_active = true;

        this.affinities_active = true;
    
        this._accountService.quizzesProfile()
        .subscribe({
            
            next: ( quizzes: QuizzesData) => {

                this.clothes_gender = quizzes.data[0];

                this.gender =  (quizzes.data[0].selected_value == "undefined")? 'null':  quizzes.data[0].selected_value;

                this.accesories = quizzes.data.filter(quiz => quiz.group_name === 'profile_affinities');

                this.brand_quiz = quizzes.data[11];

                this.cards = quizzes.data.filter(quiz => quiz.group_name === 'event_preferences');

                this.cards.sort((a, b) => {
                    const numA = isNaN(Number(a.selected_value)) ? 0 : Number(a.selected_value);
                    const numB = isNaN(Number(b.selected_value)) ? 0 : Number(b.selected_value);
                    return numA - numB;
                });

                this.quiz_active = false;

                this.affinities_active = false;

            },
            error: () => {

            }
        });
    }

    public async attachQuiz (customer_uuid: string, quiz_uuid:string, selected_value: string): Promise<GralResponse | void>{
        try {
            return await firstValueFrom(
                this._accountService.attatchQuiz(customer_uuid,quiz_uuid, selected_value)
            );
        } catch (error: any) {
            console.error('Error al adjuntar respuesta de afinidades:', error);
            throw new Error('Error al adjuntar respuesta de afinidades.');
        }
    }

    public async attachQuizzes (customer_uuid: string, quiz_uuids:string[], selected_values: string[]): Promise<GralResponse | void>{
        
        try {

            return await firstValueFrom(
                this._accountService.attatchQuizzes(customer_uuid,quiz_uuids,selected_values)
            );
        } catch (error: any) {
            console.error('Error al adjuntar respuesta de afinidades:', error);
            throw new Error('Error al adjuntar respuesta de afinidades.');
        }
    }


    showModal( tipo: string) {   
        let src = '';
        tipo === 'casco' ? src = 'assets/images/casco.jpeg': '';
        tipo === 'guantes' ? src = 'assets/images/guantes.jpeg': '';
        tipo === 'zapatos' ? src = 'assets/images/zapatos.jpeg': '';
        let imagen = src;
        let legal = "";
    
        this.modal.nativeElement.style.display = "grid";
        this.modalImg.nativeElement.src = imagen;  
        this.caption.nativeElement.innerHTML = legal ;
      }

      closeModal( message:string ) {    
        if( message == "no" ) {
          this.execute = 'no';
        }else if ( message == "yes" && this.execute == 'no' ){
          this.execute = 'processing';
        }else {
          this.execute = 'yes';
        }
        if( this.execute == 'yes' ){
          this.modal.nativeElement.style.display = "none";
        }    
      }

}
