 <div class="container" style="margin-top: 70px;">


     <h1>Aviso de Privacidad</h1>

     <p>En cumplimiento con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y con el
         fin de
         asegurar la protección de los datos personales, así como regular el acceso, rectificación, cancelación y
         oposición
         del manejo de los mismos, con fundamento en lo dispuesto por los artículos 16 Constitucional, 1ro y 2do de la
         Ley
         Federal de Protección de Datos Personales en Posesión de los Particulares, VEHÍCULOS EUROPEOS DE CALIDAD
         HIDALGO,
         S.A. DE C.V. establece el siguiente:</p>

     <h2>AVISO DE PRIVACIDAD</h2>

     <p>VEHÍCULOS EUROPEOS DE CALIDAD HIDALGO, S.A. DE C.V. con domicilio ubicado en Distribuidor Vial La Paz # 113 Col.
         Adolfo Lopez Mateos C.P. 42094 Pachuca, Hidalgo, le informa que sus datos personales y datos personales
         sensibles,
         se utilizarán para identificación, operación y análogos que sean necesarios para la explotación comercial,
         negocio
         de compra, venta y arrendamiento de vehículos automotores, camionetas y motocicletas, nuevos y/o usados, sus
         accesorios, así como talleres de servicio y reparación en el ramo de automóviles, camionetas y motocicletas de
         demás que se relacionen con dichas actividades, así como comisiones y representaciones y cualquier actividad
         que se
         relacione con la actividad comercial e industrial de toda clase de vehículos automotores.</p>

     <h2>POLÍTICA DE PRIVACIDAD</h2>

     <p>Esta política tiene como fin asegurar la privacidad de los datos proporcionados por nuestros clientes,
         empleados,
         colaboradores en general, con el fin de vincularse con la explotación comercial, negocio de compra, venta y
         arrendamiento de vehículos automotores, camionetas y motocicletas, nuevos y/o usados, sus accesorios, así como
         talleres de servicio y reparación en el ramo de automóviles, camionetas y motocicletas y demás que se
         relacionen
         con dichas actividades, así como comisiones y representaciones y cualquier actividad que se relacione con la
         actividad comercial e industrial de toda clase de vehículos automotores. Al usar este sitio o cualquier sitio
         relacionado con los servicios y/o productos brindados por VEHÍCULOS EUROPEOS DE CALIDAD HIDALGO, S.A. DE C.V.
         Usted
         está de acuerdo con la recopilación, uso, transferencia y almacenamiento de su información personal y personal
         sensible, lo que significa que ha leído, entendido y aceptado los términos a continuación expuestos. En caso de
         no
         estar de acuerdo con ellos, el titular NO deberá proporcionar ninguna información personal.</p>

     <h2>Datos Personales Recabados</h2>

     <p>VEHÍCULOS EUROPEOS DE CALIDAD HIDALGO, S.A. DE C.V. puede recabar datos personales, no sensibles, como son de
         manera
         enunciativa más no limitativa los siguientes:</p>

     <ul>
         <li>Nombre completo</li>
         <li>Edad</li>
         <li>Fecha de Nacimiento</li>
         <li>Género</li>
         <li>Estado Civil</li>
         <li>Información relacionada a si tiene hijos y el número de hijos</li>
         <li>Domicilio Particular</li>
         <li>Nacionalidad</li>
         <li>Correo Electrónico</li>
         <li>Teléfono particular, del trabajo, celular</li>
         <li>Forma de contacto preferida</li>
         <li>Información sobre cómo se enteró de los productos y servicios de VEHÍCULOS EUROPEOS DE CALIDAD HIDALGO,
             S.A. DE
             C.V.</li>
         <li>Número de Seguro Social.</li>
         <li>Datos de Contacto en caso de Emergencia.</li>
     </ul>

     <h2>Datos Personales Sensibles</h2>

     <p>VEHÍCULOS EUROPEOS DE CALIDAD HIDALGO, S.A. DE C.V. también podrá tratar los siguientes datos personales
         sensibles:
     </p>

     <ul>
         <li>Información financiera</li>
         <li>Información patrimonial</li>
         <li>Información respecto a los productos y servicios que usted adquiere de VEHICULOS EUROPEOS DE CALIDAD
             HIDALGO,
             S.A. DE C.V.</li>
         <li>Información general en relación con su estado de salud</li>
         <li>Enfermedades que padece o ha padecido</li>
         <li>Creencias religiosas, filosóficas y morales</li>
         <li>Afiliación Sindical</li>
         <li>Ideología política</li>
         <li>Preferencia Sexual</li>
         <li>Tipo de Sangre</li>
     </ul>

     <h2>Finalidades del Tratamiento de Datos Personales</h2>

     <p>La información tanto de los datos personales, como de los datos personales sensibles, podrán ser empleados para
         alguna de las siguientes finalidades de manera indistinta:</p>

     <ul>
         <li>Promoción de servicios relacionados con VEHÍCULOS EUROPEOS DE CALIDAD HIDALGO, S.A. DE C.V.</li>
         <li>Seguimiento y atención de los productos que se ofrecen.</li>
         <li>Registro de proveedores y sus derivados.</li>
         <li>Encuestas y evaluaciones de servicios o productos.</li>
         <li>Elaboración de estadísticas e informes respecto de los productos y/o servicios que se ofrecen.</li>
         <li>Aquellos relacionados con la identificación, operación, administración y análogos que sean necesarios para
             la
             presentación del servicio administrativo o comercial.</li>
         <li>Elaboración de estadísticas e informes respecto de los productos y servicios que se ofrecen.</li>
     </ul>

     <p>
         VEHÍCULOS EUROPEOS DE CALIDAD HIDALGO, S.A. DE C.V. en todo momento observa los principios de confidencialidad,
         licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad en la
         protección de datos personal. El uso de los datos financieros o patrimoniales requerirá el consentimiento
         expreso
         de su titular, salvo que tengan el propósito de cumplir obligaciones derivadas de una relación jurídica entre
         usted
         y VEHÍCULOS EUROPEOS DE CALIDAD HIDALGO, S.A. DE C.V., así como las demás excepciones descritas en los
         artículos 10
         y 37 de la Ley antes mencionada. Tratándose de datos personales sensibles, VEHICULOS EUROPEOS DE CALIDAD
         HIDALGO,
         S.A. DE C.V. obtiene su consentimiento expreso y por escrito para su tratamiento a través de su firma
         autógrafa,
         firma electrónica, o cualquier mecanismo de autenticación que al efecto se establezca. Sin embargo, con lo
         dispuesto en la Ley Federal de Protección de Datos Personales de Posesión de los particulares, no será
         necesario el
         consentimiento para el tratamiento de los datos personales cuando tenga el propósito de cumplir obligaciones
         derivadas de una relación jurídica entre el titular y el responsable.
     </p>

     <h2>SEGURIDAD DE LOS DATOS PERSONALES</h2>

     <p>
         VEHICULOS EUROPEOS DE CALIDAD HIDALGO, S.A DE C.V implementará las medidas de seguridad, técnicas,
         administrativas
         y físicas para proteger sus datos personales y evitar su daño, perdida, alteración destrucción o el uso, acceso
         o
         tratamiento no autorizado. los cuales serán tratados para las siguientes finalidades: Principales:</p>

     <ul>
         <li>Proveerle un bien y/o servicio.</li>
         <li>Realizar actividades de mercadeo y promoción en general.</li>
         <li>Ofrecerle nuestros productos, servicios e información de nuestros socios de negocios.</li>
         <li> Mantener actualizados nuestros registros para poder responder a sus consultas, invitarle a eventos, hacer
             válida la garantía de su vehículo, informarle acerca de llamados a revisión de su vehículo, hacer de su
             conocimiento nuestras promociones y lanzamientos, mantener comunicación en general, así como dar
             seguimiento a
             nuestra relación comercial.</li>
     </ul>

     <p>
         Únicamente el personal autorizado que ha cumplido y observado los correspondientes requisitos de
         confidencialidad,
         podrá participar en el tratamiento de sus datos personales. El personal autorizado tiene prohibido permitir el
         acceso a personas no autorizadas y utilizar sus datos para fines distintos a los establecidos en el previo
         Aviso de
         Privacidad. La obligación de confidencialidad de las personas que participan en el tratamiento de sus datos
         personales subsiste aun después de terminada la relación de trabajo o prestación de servicio con VEHÍCULOS
         EUROPEOS
         DE CALIDAD HIDALGO, S.A. DE C.V.
     </p>

     <h2>LIMITACIONES DE USO Y DIVULGACIÓN</h2>

     <p>El tratamiento de sus datos personales será el que resulte necesario, adecuado y relevante en relación con las
         finalidades previstas en esta Política dePrivacidad.</p>

     <p>VEHICULOS EUROPEOS DE CALIDAD HIDALGO, S.A. DE C.V. cumple con los principios de datos personales establecidos
         por
         la Ley Federal de Protección de Datos Personales en posesión de los Particulares, y adopta las medidas
         necesarias
         para su aplicación. Lo anterior, aplica aun y cuando datos fueren tratados por un tercero, a solicitud de
         VEHÍCULOS
         EUROPEOS DE CALIDAD HIDALGO, S.A. DE C.V. y con el fin de cubrir los servicios comerciales y administrativos
         necesarios, manteniendo la confidencialidad en todo momento.</p>

     <p>VEHÍCULOS EUROPEOS DE CALIDAD HIDALGO, S.A. DE C.V. adopta las medidas necesarias y suficientes para procurar
         que
         esta Política de Privacidad searespetada por sus socios, sus trabajadores, sus dependientes, sus comisionistas,
         sus
         vendedores o por terceros con los que guarde alguna relación, para otorgar los servicios o productos
         establecidos
         con el titular.</p>

     <h2>DERECHO DE LOS TITULARES DE DATOS PERSONALES</h2>

     <p>Cualquier titular o, en su caso, su representante legal, podrá (n) ejercer los derechos de acceso,
         rectificación,
         cancelación y oposición, previstos en la Ley Federal de Protección de Datos Personales en Posesión de los
         Particulares asimismo VEHÍCULOS EUROPEOS DE CALIDAD HIDALGO, S.A. DE C.V. proveerá los medios que le (s)
         permita
         (n) un oportuno ejercicio de sus derechos. El ejercicio de los derechos de acceso, rectificación, cancelación,
         oposición, limitación de uso o la revocación del consentimiento, deberá solicitarse por escrito y dirigido al
         área
         de Recursos Humanos de la sociedad.</p>

     <p>La revocación del consentimiento puede afectarse en cualquier momento, sin que se atribuyan efectos
         retroactivos.
         Para iniciar el proceso de revocación, deberáindicar de forma precisa el consentimiento que desea revocar
         mediante
         escrito dirigido a la sociedad denominada VEHÍCULOS EUROPEOS DE CALIDAD HIDALGO, S.A. DE C.V. domicilio de la
         responsable a la atención del Encargado de Protección de Datos Personales o bien envíe un correo electrónico a:
         privacidad@bmwvecsa.com La solicitud por escrito de acceso, rectificación, cancelación u oposición, deberá
         contener
         y acompañar lo siguiente:</p>

     <ul>
         <li>1.- El nombre del titular y domicilio u otro para comunicar la respuesta a su solicitud</li>
         <li>2.- Los documentos que acrediten la identidad, o en su caso, la representación legal del titular.</li>
         <li>3.- La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de
             los
             derechos antes mencionados.</li>
         <li>4.- Cualquier otro elemento o documento que facilite la localización de los datos personales.</li>
         <li>5.- Para el caso de las solicitudes de rectificación el titular deberá indicar las modificaciones a
             realizarse
             y aportar la documentación que sustente su petición. VEHÍCULOS EUROPEOS DE CALIDAD HIDALGO, S.A. DE C.V.
             comunicara al titular en el domicilio proporcionado para tal efecto, dentro de los veinte díashábiles
             contados
             a partir de la fecha de recepción de la solicitud de acceso rectificación o cancelación u oposición, la
             determinación adoptada a efecto de que, si resulta procedente, se haga efectiva la misma dentro de los
             quince
             días siguientes a la fecha en que se comunica la respuesta. Tratándose de solicitudes de acceso a datos
             personales, procederá la entrega previa acreditación de la identidad del solicitante o representante legal,
             según corresponda. Los plazos antes referidos, podrán ser ampliados una sola vez por un período igual,
             siempre
             y cuando así lo justifiquen las circunstancias del caso.</li>
     </ul>

     <p>La obligación de acceso a la información, se dará por cumplida cuando se pongan a disposición del titular los
         datos
         personales;
         o bien mediante la expedición decopias simples, documentos electrónicos o cualquier otro medio que VEHÍCULOS
         EUROPEOS DE CALIDAD HIDALGO, S.A. DE C.V. provea al titular.
         En caso de que el titular solicite el acceso de los datos de una persona que se presume es el responsable, y
         ésta
         no resulta serlo, bastará con que así se leindique al titular de los medios impresos (carta de no procedencia)
         para
         tener por cumplida la solicitud.
     </p>

     <p>VEHÍCULOS EUROPEOS DE CALIDAD HIDALGO, S.A. DE C.V. podrá negar el acceso a los datos personales, la
         rectificación,
         cancelación o concesión de la oposición de los mismos, en los siguientes supuestos:</p>

     <ul>
         <li>1.- Cuando el solicitante no sea el titular de los datos personales, o el representante legal no esté
             debidamente acreditado para ello</li>
         <li>2.- Cuando en la base de datos de VEHÍCULOS EUROPEOS DE CALIDAD HIDALGO, S.A. DE C.V. no estén registrados
             los
             datos personales del solicitante.</li>
         <li>3.- Cuando se lesionen los derechos de un tercero</li>
         <li>4.- Cuando exista un impedimento legal o la resolución de una autoridad competente que restrinja el acceso
             a
             los datos personales o que no importa larectificación, cancelación u oposición de los mismos.</li>
         <li>5.-Cuando la rectificación, cancelación u oposición haya sido previamente realizada.</li>
     </ul>

     <p>
         VEHÍCULOS EUROPEOS DE CALIDAD HIDALGO, S.A. DE C.V. limitará el uso de los datos personales y datos personales
         sensibles a petición expresa deltitular, y no estará obligada a cancelar los datos personales cuando:</p>

     <ul>
         <li>1.- Se refiera a las partes de un contrato privado, social o administrativo, y sean necesarios para su
             desarrollo y cumplimiento.</li>
         <li>2.- Sean tratados por disposición legal</li>
         <li>3.- Obstaculice actuaciones judiciales o administrativas vinculadas a obligaciones fiscales, la
             investigación y
             persecución de delitos, o la actualización desanciones administrativas.</li>
         <li>4.- Sean necesarios para proteger los intereses jurídicos tutelados.</li>
         <li>5.-Sean necesarios para realizar una acción en función del interés público.</li>
         <li>6.- Sean necesarios para cumplir con una obligación legalmente adquirida por el titular, o</li>
         <li>7.- Sean objeto de tratamiento para la prevención o diagnóstico médico, o la gestión de servicios de salud,
             siempre que dicho tratamiento se realice por unprofesional de la salud sujeto a un deber de secreto.</li>
     </ul>
     <p>
         El cambio de la presente Política de Privacidad podrá efectuarse por esta sociedad en cualquier momento y
         estará
         disponible al público en general en lasinstalaciones de la sociedad.</p>
 </div>