<div class="img-container">
        <div class="col-12">
            <picture>
                <!-- Imagen para pantallas grandes -->
                <source media="(min-width: 1000px)" srcset="./assets/img/BMW2_1720X472.webp">
                <!-- Imagen para pantallas medianas -->
                <source media="(min-width: 768px)" srcset="./assets/img/BMW_820X820.webp">
                <!-- Imagen para pantallas pequeñas -->
                <img src="./assets/img/BMW_820X820.webp" class="img2" alt="">
            </picture>
        </div>
</div>

 