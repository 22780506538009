<div class="container">
    <div class="row">    
        <div class="col-12 mt-5 mb-5 mx-auto text-center">    
            <img class="rounded mx-auto d-block" width="350" src="assets/icons/404.svg" alt="Not Found" />                    
            <h1 class="fw-bold mt-5">PÁGINA NO ENCONTRADA</h1>
            <p class="lead">
                La página que busca no existe. Cómo llegaste aquí es un misterio.                    
            </p>

            <div class="mt-5">
            </div>
        </div>    
    </div>
</div>