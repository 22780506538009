import { Component } from '@angular/core';

@Component({
  selector: 'app-form-rider',
  templateUrl: './form-rider.component.html',
  styleUrls: ['./form-rider.component.css']
})
export class FormRiderComponent {

}
