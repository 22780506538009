<!-- <div class="container">
    <div class="row">
        <div class="col-12 py-5" style="margin-top: 95px; margin-bottom: 200px;">
            <div class="row">
                <div class="col-12 py-4">
                    <img class="rounded mx-auto d-block" width="100" src="assets/icons/login.svg" alt="User Icon" />
                </div>                                
            </div>

            <div class="row">
                <h1 class="fs-3 fw-bold mb-4 text-center mx-auto">Registrarme</h1>
                <div class="col-12 mx-auto">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="row mb-2">
                            <div class="col-10 col-md-7 col-lg-4 mx-auto">
                                
                                <mat-form-field appearance="outline">
                                    <mat-label>Nombre(s)</mat-label>
                                    <input matInput type="text" placeholder="Jorge" formControlName="name" required>
                                    <mat-hint class="text-danger" *ngIf="nameInvalid">Complete su nombre(s).</mat-hint>
                                </mat-form-field>
    
                                <mat-form-field appearance="outline">
                                    <mat-label>Apellido(s)</mat-label>
                                    <input matInput type="text" placeholder="Sanchez" formControlName="last_name" required>
                                    <mat-hint class="text-danger" *ngIf="lastnameInvalid">Complete sus apellidos.</mat-hint>
                                </mat-form-field>

                                <mat-form-field appearance="outline">
                                    <mat-label>Correo Electrónico</mat-label>
                                    <input matInput type="email" placeholder="a@b.c" formControlName="email" required>
                                    <mat-hint class="text-danger" *ngIf="emailInvalid">Ingrese un correo electrónico.
                                    </mat-hint>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-10 col-md-7 col-lg-4 mx-auto">
                                <mat-form-field appearance="outline">
                                    <mat-label>Contraseña</mat-label>
                                    <input matInput [type]="hide ? 'password' : 'text'" placeholder="********" formControlName="password" required>
                                    <button mat-icon-button type="button" matSuffix (click)="hide = !hide">
                                        <img [src]="hide ? 'assets/img/login/icono-ojo-abierto.png' : 'assets/img/login/icono-ojo-cerrado.png'" alt="Icono de imagen" width="24" height="24">
                                    </button>

                                </mat-form-field>
                                <mat-hint class="text-danger">
                                    <div class="row">
                                        <div class="col-12">
                                            <p *ngIf="passwordInvalid">La contraseña debe contener al menos una letra mayúscula [A-Z], una letra minúscula [a-z], un dígito [0-9], y un carácter especial permitido: &#64; $ ! % * ? &.</p>
                                        </div>
                                        <div class="col-12">
                                            <p *ngIf="passwordLength">La contraseña debe ser de minímo 8 a 32 caracteres.</p>
                                        </div>
                                    </div>
                                </mat-hint>
                            </div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-10 col-md-7 col-lg-4 mx-auto">
                                <mat-form-field appearance="outline">
                                    <mat-label>Confirmar Contraseña</mat-label>
                                    <input matInput type='password' formControlName="confirmPassword" required>
                                </mat-form-field>
                                <mat-hint class="text-danger">
                                <div class="row">
                                    <div class="col-12">
                                    <p *ngIf="form.hasError('mismatch') && (form.get('confirmPassword')!.dirty || form.get('confirmPassword')!.touched)">Las contraseñas no coinciden.</p>
                                    </div>
                                </div>
                                </mat-hint>

                                <p>¿Ya tienes cuenta? <a class="abcars-color-hover text-decoration-none" [routerLink]="['/auth/iniciar-sesion']">Inicia Sesión</a></p>

                            </div>
                        </div>

                        <div class="row">
                            <div class="col-8 col-md-4 mx-auto text-center mt-5">
                                <button mat-raised-button type="submit"
                                    class="btn abcars-background-color-blue mt-3  mx-2 px-3"
                                    [disabled]="form.invalid || spinner">
                                    <span *ngIf="!spinner">Iniciar</span>
                                    <span *ngIf="spinner" class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                </button>
                            </div>
                        </div>                        
                    </form>
                </div>
            </div>
        </div>        
    </div>
</div> -->
<div id="top"></div>
<app-new-nav></app-new-nav>
<div class="container">
    <div>
        <div class="row">
            <ng-container *ngIf="active; else elseTemplate">
                <app-sk-cube></app-sk-cube>
            </ng-container>

            <ng-template  #elseTemplate>

                <div class="row align-items-center mt-3">
                    <div class="col-auto"></div>
                
                    <div class="col-auto ">
                        <h3 class="fw-bold mb-0" style="font-size: x-large;">Tu perfíl</h3>
                        <br>
                        <h6 class="fw-light texto" style="text-align: justify;">Los datos que componen tu perfíl nos ayudan a reconocerte y atenderte de manera más personalizada. <br> Gracias por mantenerlos actualizados.</h6>
                    </div>
            
                    <div class="col-auto"></div>
                </div>
            
                <hr />
                
                <div class="row">
                    <!-- Picture -->
                    <div class="col-12 col-md-3 mt-4">
                        <div class="foto">
                            <div class="row align-items-center" style="justify-self: center; text-align: center;">
                                <div class="col-6 col-md-12 col-lg-12 col-img">
                                    <img id="imagePreview" [src]="image_path" alt="User Picture Imagen" class="img-fluid-f">
                                </div>

                                <div class="col-6 col-md-12 col-lg-12 col-img mb-4">
                                    <button mat-raised-button type="submit" class="btn abcars-background-color-blue mt-3 mx-2 px-3" (click)="avatar()">
                                        <i class="fa-solid fa-pen-to-square"></i> Subir Imagen
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Information -->
                    <div class="col-12 col-md-9 mt-4 mb-5">
                        <h5 class="fw-bold text-dark">Tu información</h5>
            
                        <!-- Form Customer -->
                        <form [formGroup]="form">
                            
                            <div class="row">
                                <div class="col-12 col-md-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Nombre(s)</mat-label>
                                        <input matInput type="text" placeholder="Jorge" formControlName="name" required>
                                        <mat-hint class="text-danger" *ngIf="nameInvalid">Complete su nombre(s).</mat-hint>
                                    </mat-form-field>
                                </div>
                            
                                <div class="col-12 col-md-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Apellido(s)</mat-label>
                                        <input matInput type="text" placeholder="Sanchez" formControlName="last_name" required>
                                        <mat-hint class="text-danger" *ngIf="lastnameInvalid">Complete sus apellidos.</mat-hint>
                                    </mat-form-field>
                                </div>

                                <div class="col-12 col-md-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Fecha de nacimiento</mat-label>
                                            <input matInput type="date" formControlName="birthday" required>
                                            <mat-hint class="text-danger" *ngIf="dateInvalid">Seleccione la fecha de cumpleaños.</mat-hint>
                                    </mat-form-field>
                                </div>

                                <div class="col-12 col-md-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Teléfono</mat-label>
                                            <input matInput type="text" placeholder="123456789" formControlName="phone_1" maxlength="10" required>
                                            <mat-hint class="text-danger" *ngIf="phoneInvalid">Ingrese un teléfono válido.</mat-hint>
                                    </mat-form-field>
                                </div>

                                <div class="col-12 col-md-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Correo Electrónico</mat-label>
                                            <input matInput type="email" placeholder="a@b.c" formControlName="email" required>
                                            <mat-hint class="text-danger" *ngIf="emailInvalid">Ingrese un correo electrónico.</mat-hint>
                                    </mat-form-field>
                                </div>

                                <div class="col-12 col-md-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Contraseña</mat-label>
                                        <input matInput [type]="hide ? 'password' : 'text'" placeholder="********" formControlName="password" required>
                                        <button mat-icon-button type="button" matSuffix (click)="hide = !hide">
                                            <img [src]="hide ? 'assets/img/login/icono-ojo-abierto.png' : 'assets/img/login/icono-ojo-cerrado.png'" alt="Icono de imagen" width="24" height="24">
                                        </button>
    
                                    </mat-form-field>
                                    <mat-hint class="text-danger">
                                        <div class="row">
                                            <div class="col-12">
                                                <p *ngIf="passwordInvalid">La contraseña debe contener al menos una letra mayúscula [A-Z], una letra minúscula [a-z], un dígito [0-9], y un carácter especial permitido: &#64; $ ! % * ? &.</p>
                                            </div>
                                            <div class="col-12">
                                                <p *ngIf="passwordLength">La contraseña debe ser de minímo 8 a 32 caracteres.</p>
                                            </div>
                                        </div>
                                    </mat-hint>
                                </div>

                                <div class="col-12 col-md-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Confirmar Contraseña</mat-label>
                                        <input matInput type='password' formControlName="confirmPassword" required>
                                    </mat-form-field>
                                    <mat-hint class="text-danger">
                                    <div class="row">
                                        <div class="col-12">
                                        <p *ngIf="form.hasError('mismatch') && (form.get('confirmPassword')!.dirty || form.get('confirmPassword')!.touched)">Las contraseñas no coinciden.</p>
                                        </div>
                                    </div>
                                    </mat-hint>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                
                <div class="row mt-5 align-items-center">
                    <div class="col-auto"></div>
                
                    <div class="col-auto">
                        <h3 class="fw-bold mb-0" style="font-size: x-large;">Tallas de tu preferencia</h3>
                        <h6 class="fw-light texto" style="text-align: justify;">Queremos asegurarnos de contar en nuestro inventario con las prendas y accesorios especialmente elegidas para ti.</h6>
                    </div>
                    
                    <div class="col-auto"></div>
                </div>

                <div class="records row mb-5"> 
                    <ng-container *ngIf="quiz_active; else quizzes_accesories">
                        <app-sk-cube></app-sk-cube>
                    </ng-container>
        
                    <ng-template  #quizzes_accesories>

                        <div #myModal class="modal" (click)="closeModal('yes')">
                            <div class="modal-container position-relative">
                                <img class="modal-content" #img01>
                                <span class="btnClose" (click)="closeModal('yes')">
                                    <img src="assets/icons-fontawesome/x.png" alt="flecha" width="10px" height="10px">
                                </span>
                                <div class="texto"> 
                                    <div #caption></div> 
                                </div> 
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-auto p-2">
                                <div class="d-flex align-items-center justify-content-end" style="height: 100%;">
                                    <h6 class="fw-light me-2 mb-0 texto">¿Cómo saber mi talla?</h6>
                                    <button mat-raised-button (click)="showModal('casco')" class="btn mt-3  mx-2 px-3" style="background-color: #008bcc; color: #fff;">                                
                                        <span>Casco</span>                   
                                    </button>
                                    <button mat-raised-button (click)="showModal('guantes')" class="btn mt-3  mx-2 px-3" style="background-color: #2388b7; color: #fff;">                                
                                        <span>Guantes</span>                   
                                    </button>
                                    <button mat-raised-button (click)="showModal('zapatos')" class="btn mt-3  mx-2 px-3" style="background-color: #429dc7; color: #fff;">                                
                                        <span>Zapatos</span>                   
                                    </button>
                                </div>
                            </div>
                        
                            <div class="col auto p-2"></div>
                            
                            <div class="col-12 col-lg-4 p-2">
                                <div class="d-flex align-items-center justify-content-end" style="height: 100%;">
                                    
                                    <h6 class="fw-light me-2 mb-0" style="font-size: medium; padding-right: 10px;">Género:</h6>

                                    <mat-chip-listbox class="mat-chip-grid" aria-label="{{ clothes_gender!.name }}" (change)="onChipGenderChange($event, clothes_gender!.uuid)">
                                        <div *ngFor="let value of clothes_gender?.values; let i = index">
                                            <mat-chip-option 
                                                class="chip-option"
                                                [value]="value"
                                                [selected]="value === clothes_gender?.selected_value">
                                                {{ value }}
                                            </mat-chip-option>
                                        </div>
                                    </mat-chip-listbox>

                                </div>
                            </div>
                        </div>
        
                        <hr />

                        <ng-container *ngFor="let quiz of accesories; let i = index">
                            <div class="col-12 col-lg-6 mt-3 mb-3" style="padding: 0;" *ngIf="!(((quiz.question_type == 'ropa femenina' && (gender != 'M'))  || ( quiz.question_type == 'ropa masculina'  && (gender == 'M'))))">
                                <div class="row align-items-center">
                                    <div class="col-2 d-flex justify-content-center">
                                        <div class="image_container">
                                            <img class="image_quiz" src="{{ quiz.image_path }}" alt="{{ quiz.name }}">
                                        </div>
                                    </div>
                                    
                                    <div class="col-10">
                                        <div class="mb-2 mt-2">
                                            <h6 class="fw-light mb-0" style="font-size: medium;">{{ quiz.name }}:</h6>
                                        </div>
                                        <div class="d-flex flex-wrap">
                                            <mat-chip-listbox class="mat-chip-grid" aria-label="{{ quiz.name }}" (change)="onChipSelectionChange($event, quiz.uuid)">
                                                <div *ngFor="let value of quiz.values; let i = index">
                                                    <mat-chip-option 
                                                        class="chip-option"
                                                        [value]="value"
                                                        [selected]="value === quiz.selected_value">
                                                        {{ value }}
                                                    </mat-chip-option>
                                                </div>
                                            </mat-chip-listbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        
                        
                    </ng-template>
                </div> 

                    <div class="row mt-5 align-items-center">
                        <div class="col-auto"></div>
                    
                        <div *ngIf="!isMobileView" class="col" style="text-align: justify;">
                            <h3 class="fw-bold mb-0" style="font-size: x-large;">Gustos y afinidades</h3>
                            <h6 class="fw-light texto" style="font-size: large; text-align: justify;">Nuestras marcas te ofrecen experiencias únicas y queremos que las disfrutes al máximo. Ayúdanos a conocer cuales te resultan más afines ordenandolas de izqueda a derecha. Izquierda es menos afín, y derecha más afín.</h6>
                        </div>

                        <div *ngIf="isMobileView" class="col" style="text-align: justify;">
                            <h3 class="fw-bold mb-0" style="font-size: x-large;">Gustos y afinidades</h3>
                            <h6 class="fw-light texto" style="text-align: justify;">Nuestras marcas te ofrecen experiencias únicas y queremos que las disfrutes al máximo. Ayúdanos a conocer cuales te resultan más afines ordenandolas de arriba hacia abajo. Arriba es menos afín, y abajo más afín.</h6>
                        </div>
                        
                        <div class="col-auto"></div>
                    </div>

                 <div class="row mb-3"> 
                    <div class="col-auto"></div> 
                
                    <div class="col auto"></div>
                    
                    <div class="col-12 col-lg-8">
                        <div class="d-flex align-items-center justify-content-end" style="height: 100%;">
                            <h6 class="fw-light mb-0" style="font-size: medium; padding-right: 20px;">Tu marca actual:</h6>

                            <mat-chip-listbox class="mat-chip-grid" aria-label="{{ brand_quiz?.name }}" (change)="onChipBrandChange($event, brand_quiz!.uuid)">
                                <div *ngFor="let value of brand_quiz?.values; let i = index">
                                    <mat-chip-option 
                                        class="chip-option"
                                        [value]="value"
                                        [selected]="value === brand_quiz?.selected_value"
                                    >
                                        <img
                                            style="width: 100%; height: 100%;"
                                            matChipAvatar
                                            src="assets/brands/{{ value }}.jpeg"
                                            alt="{{ value }}"
                                        />
                                        {{ value }}
                                    </mat-chip-option>
                                </div>
                            </mat-chip-listbox>

                        </div>
                    </div>
                </div>

                <hr />

                    <div
                        cdkDropList 
                        [cdkDropListData]="cards"
                        (cdkDropListDropped)="drop($event)"
                        [cdkDropListOrientation]="isMobileView ? 'vertical' : 'horizontal'"
                        class="row d-flex justify-content-center"
                        style="gap: 10px;"
                    >
                        <div
                            *ngFor="let card of cards"
                            class="col-10 col-md-2"
                            cdkDrag
                            style="padding: 0; max-width: 100%;"
                        >
                            <div *ngIf="!isMobileView" class="card text-center">
                                <div class="card-body" style="height: 12rem;">
                                    <h3  class="card-title name-Events">{{ card.name }}</h3>
                                    <p style="font-size: small;" class="card-text">{{ card.description }}</p>
                                </div>
                                <img [src]="card.image_path" class="card-img-top" alt="{{ card.name }}" style="border-radius: 25px; height: 100%; padding: 5% ">
                            </div>

                        <div *ngIf="isMobileView" class="card special-card mb-1">
                            <div class="row g-0" style="height: 100px;">
                                <div class="col-4 d-flex align-items-center justify-content-center">
                                    <img [src]="card.image_path" class="image_card img-fluid rounded-start" alt="{{ card.name }}" style="border-radius: 25px !important; width: 80px; height: 80px; object-fit: cover; padding: 5%;">
                                </div>
                            
                                <div class="col-8 d-flex align-items-center">
                                    <div class="card-body d-flex flex-column justify-content-center">
                                        <h5 class="card-title mb-1 text-center">{{ card.name }}</h5>
                                        <p class="card-text text-center">{{ card.description }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                    <div class="row">
                        <div class="col-12 col-md-4 mt-3 mx-auto text-center">
                            <div class="text-center mt-3">
                                <button mat-raised-button type="submit"
                                    (click)="onSubmit()"
                                    class="btn abcars-background-color-blue mt-3  mx-2 px-3"
                                    [disabled]="form.invalid || !isFormValid || !status || !statusCards || spinner || file === null ">
                                    <span *ngIf="!spinner">Registrarse</span>
                                    <span *ngIf="spinner" class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>                                    
                                </button>
                            </div>
                        </div>
                    </div>
            </ng-template>

        </div>
    </div>
</div>

<div class="return-container">
    <a (click)="scrollToTop()" class="return-link">
        <img src="assets/icons-fontawesome/circle-up-solid.svg" alt="flecha" width="35px" height="35px">
    </a>
</div>
