import { Component } from '@angular/core';

@Component({
  selector: 'app-cardscontacto',
  templateUrl: './cardscontacto.component.html',
  styleUrls: ['./cardscontacto.component.css']
})
export class CardscontactoComponent {

}
